.lavery-view-home-categories {
  position: absolute;
  top: 130px;
  bottom: 80px;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.lavery-view-home-car {
  height: 100px;
  overflow: hidden;
  display: block;
  margin: 15px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 0px 6px rgba(152, 152, 152, 0.5);
}

.lavery-view-home-car.active {
  box-shadow: 0px 0px 6px rgb(82, 151, 255);
}

.lavery-view-home-car.active .lavery-view-home-car-checked {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 35px;
  background-image: url("./icon-check.svg");
  background-size: 100%;
}

.lavery-view-home-car .lavery-view-home-car-icon {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-size: 100%;
  margin-left: 15px;
  vertical-align: middle;
}

.lavery-view-home-car.mini .lavery-view-home-car-icon {
  background-image: url("./car-mini.jpg");
}

.lavery-view-home-car.pequeno .lavery-view-home-car-icon {
  background-image: url("./car-pequeno.jpg");
}

.lavery-view-home-car.medio .lavery-view-home-car-icon {
  background-image: url("./car-medio.jpg");
}

.lavery-view-home-car.grande .lavery-view-home-car-icon {
  background-image: url("./car-grande.jpg");
}

.lavery-view-home-car.extragrande .lavery-view-home-car-icon {
  background-image: url("./car-extragrande.jpg");
}

.lavery-view-home-car-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  margin-left: 20px;
  color: #909090;
  text-transform: uppercase;
  font-size: 13px;
}