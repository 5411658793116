.lavery-component-header {
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 3;
  padding-top: 14px;
  background: #FFFFFF;
  position: relative;
}

.lavery-component-header-logo {
  width: auto;
  height: 42px;
  display: block;
  margin: auto;
  cursor: pointer;
}

.lavery-component-header-back {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  background-image: url("./icon-left.svg");
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
}

.lavery-componente-header-price {
  background: green;
  color: #FFF;
  font-weight: bold;
  padding: 6px 15px;
  border-radius: 100px;
  position: absolute;
  right: 16px;
  top: 24px;
  font-size: 12px;
}