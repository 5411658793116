.lavery-component-step-label {
  height: 60px;
  text-align: center;
  padding-top: 20px;
  border-bottom: 1px solid #f5f5f5;
}

.lavery-component-step-label-step {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #0e6cfc;
  color: #fff;
  border-radius: 100px;
  margin-right: 10px;
  margin-top: -2px;
}

.lavery-component-step-label-text {
  font-size: 16px;
  font-weight: bold;
  color: #0e6cfc;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}