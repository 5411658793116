.lavery-view-washes-types {
  position: absolute;
  top: 130px;
  bottom: 80px;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.lavery-view-washes-type {
  height: 100px;
  overflow: hidden;
  display: block;
  margin: 15px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 0px 6px rgba(152, 152, 152, 0.5);
}

.lavery-view-washes-type.active {
  box-shadow: 0px 0px 6px rgb(82, 151, 255);
}

.lavery-view-washes-type.active .lavery-view-washes-type-checked {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 35px;
  background-image: url("./icon-check.svg");
  background-size: 100%;
}

.lavery-view-washes-type .lavery-view-washes-type-icon {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-size: 100%;
  margin-left: 15px;
  vertical-align: middle;
}

.lavery-view-washes-type.ducha-pretinho .lavery-view-washes-type-icon {
  background-image: url("./servico-ducha.jpg");
}

.lavery-view-washes-type.ducha-pretinho-secagem .lavery-view-washes-type-icon {
  background-image: url("./servico-ducha-secagem.jpg");
}

.lavery-view-washes-type.lavagem-simples .lavery-view-washes-type-icon {
  background-image: url("./servico-lavagem-simples.jpg");
}

.lavery-view-washes-type.lavagem-simples-cera .lavery-view-washes-type-icon {
  background-image: url("./servico-lavagem-simples-cera.jpg");
}

.lavery-view-washes-type.lavagem-completa .lavery-view-washes-type-icon {
  background-image: url("./servico-lavagem-completa.jpg");
}

.lavery-view-washes-type.lavagem-completa-cera .lavery-view-washes-type-icon {
  background-image: url("./servico-lavagem-completa-cera.jpg");
}

.lavery-view-washes-type-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  margin-left: 20px;
  color: #909090;
  text-transform: uppercase;
  font-size: 13px;
  width: 120px;
  line-height: 18px;
}