.lavery-component-submit {
  position: absolute;
  width: 100%;
  height: 80px;
  bottom: 0;
  border-top: 1px solid #ececec;
  padding: 10px 15px 0;
}

.lavery-component-submit-btn {
  display: block;
  width: 100%;
  height: 53px;
  line-height: 50px;
  border-radius: 8px;
  background: #dadada;
  color: #b9b9b9;
  pointer-events: inherit;
  border: 0;
  font-weight: bold;
  font-family: TitilliumWeb-Regular;
  font-size: 15px;
  text-align: left;
  padding-left: 25px;
  transition: all .2s ease-out;
  background-image: url("./icon-right.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
}

.lavery-component-submit-btn.active {
  background: #0e6cfc;
  color: #fff;
  pointer-events: initial;
  transition: all .2s ease-out;
  background-image: url("./icon-right-active.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
}